import { required, helpers, email } from '@vuelidate/validators';
import regexUtil from '@/utils/regex';

const mobileValidation = helpers.regex(regexUtil.mobile.generic);
const postCodeValidation = helpers.regex(regexUtil.postalCode.aus);

export const RequestTenantReference = {
  form: {
    agency_name: {
      required: helpers.withMessage('Business name is required.', required),
    },
    property_manager_name: {
      required: helpers.withMessage(
        'Property Manager name is required.',
        required
      ),
    },
    property_manager_mobile: {
      required: helpers.withMessage(
        'Property Manager mobile number is required.',
        required
      ),
      mobileValidation: helpers.withMessage(
        'Mobile is not a valid format',
        mobileValidation
      ),
    },
    property_manager_email: {
      required: helpers.withMessage(
        'Property Manager email is required.',
        required
      ),
      email: helpers.withMessage('Email is not a valid format', email),
    },
    first_name: {
      required: helpers.withMessage('Tenant first name is required.', required),
    },
    last_name: {
      required: helpers.withMessage('Tenant last name is required.', required),
    },
    address_line: {
      required: helpers.withMessage('Address is required.', required),
    },
    city: { required: helpers.withMessage('Suburb is required.', required) },
    state: { required: helpers.withMessage('State is required.', required) },
    post_code: {
      required: helpers.withMessage('Post code is required.', required),
      postCodeValidation: helpers.withMessage(
        'Postal code is not a valid format. Ex. XXXX',
        postCodeValidation
      ),
    },
    country: {
      required: helpers.withMessage('Country is required.', required),
    },
  },
};
